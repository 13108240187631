<template>
    <v-container v-if="$store.state.user.components.includes(82)">
        <v-card>
            <v-card-text>
                <crud model="Medication" :headers="headers" label="Medications" :conditions="[{deleted:{is:null}}]"></crud>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import crud from "../../components/CRUD.vue";
export default {
    components: {
        crud
    },
    data() {
        return {
            headers: [
                {
                    value: "id",
                    text: "ID",
                    visible: false,
                    editable: false,
                    isId: true
                },
                {
                    value: "name",
                    text: "Name",
                    mandatory: true,
                    searchable: true
                },
                {
                    value: "alias",
                    text: "Alias",
                    mandatory: true,
                    visible: true
                },
                {
                    value: "POC.name",
                    text: "POC",
                    visible: true,
                    model: "POC",
                    modelId: "poc_id",
                    modelFields: {value: "id", text: "name" },
                    type: "select",
                },
            ]
        };
    }
};
</script>