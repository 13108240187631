<template>
    <v-container v-if="$store.state.user.components.includes(81)">
        <v-card>
            <v-card-text>
                <crud model="Diagnosis" :headers="headers" label="ICD10 Diagnoses Codes"></crud>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import crud from "../../components/CRUD.vue";
export default {
    components: {
        crud
    },
    data() {
        return {
            headers: [
                {
                    value: "id",
                    text: "ID",
                    visible: false,
                    editable: false,
                    isId: true
                },
                {
                    value: "name",
                    text: "Name",
                    mandatory: true,
                    editable: false,
                    searchable: true
                },
                {
                    value: "icd10_raw",
                    text: "Raw",
                    mandatory: true,
                    visible: false
                },
                {
                    value: "icd10_code",
                    text: "Code",
                    mandatory: true,
                    visible: false
                },
                {
                    value: "icd10_description",
                    text: "Description",
                    mandatory: true,
                    visible: false
                }
            ]
        };
    }
};
</script>